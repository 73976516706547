.mnt-backButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0.94118rem;

  svg {
    fill: $codgray;
    width: 19px;
    height: 24px;
  }
  span {
    font-family: $font-family-secondary;
    font-weight: $font-weight-bold;
    font-size: 17px;
    padding-left: 10px;
    color: $codgray;
  }
}
