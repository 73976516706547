.mnt-Feed {
  width: 100%;
  position: relative;
  z-index: 1;

  .mnt-Post-hash:before {
    display: block;
    content: ' ';
    margin-top: -55px;
    height: 55px;
    visibility: hidden;
  }
}

.mnt-endOfFeed {
  width: 100%;
  padding: 1rem 0 3rem 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }

  .mnt-logoMnt {
    display: inline-flex;

    svg {
      fill: $black;
    }
  }

  .mnt-darkTheme & {
    svg {
      fill: $white;
    }
  }
}

.mnt-fb-post {
  text-align: center;
}
