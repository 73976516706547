a {
  .mnt-Minuta &,
  .mnt & {
    //color: $monza;
    text-decoration: none;
  }
}
p {
  .mnt-Minuta & {
    font-size: $font-size-normal;
    line-height: 28px;
    font-weight: $font-weight-light;
    @include media-breakpoint-up(md) {
      font-size: 20px;
      line-height: $line-height-26;
    }
  }
}
.mnt-Minuta {
  .mnt-sansMd {
    font-family: $font-family-secondary;
    font-weight: $font-weight-medium;
  }
}

