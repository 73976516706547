.mnt-articleLive {
  padding: 20px !important;
  display: block;
  border-bottom: 1px solid rgba(#181818, 0.07);

  .mnt-liveLink-wrapper {
    display: grid;
    grid-template-columns: 1fr 110px;
    grid-column-gap: 20px;
    justify-content: space-between;
  }

  .mnt-liveLink {
    color: $monza;
    font-weight: $font-weight-medium;
    font-size: $font-size-huge;
    line-height: 25px;
    @include media-breakpoint-down(md) {
      font-size: 19px;
      line-height: 17px;
    }
  }

  .mnt-liveLink-noImg {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    .mnt-liveLink {
      font-size: $font-size-huge;
      line-height: 25px;
    }
  }

  .mnt-liveLink:hover {
    color: $link_hover;
  }

  .mnt-liveAuthor {
    padding-top: 20px;
  }

  .mnt-liveShare-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .mnt-live-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .mnt-time.mnt-time-spaced {
        margin-left: 15px;
      }
    }

    .mnt-live-header {
      .mnt-time {
        text-transform: lowercase;
        font-family: $font-family-secondary;
        font-weight: $font-weight-normal;
        transition: color .25s ease 0s;
        font-size: $font-size-semi;
        color: rgba($black, .5);
      }
    }

    .mnt-External {
      position: relative;
      left: 10px;
      padding: 10px;
      display: flex;

      svg {
        width: 20px;
        height: 17px;
        fill: rgba($black, .4);
        transition: fill 0.3s ease 0s;
      }

      &:hover {
        svg {
          fill: rgba($black, 0.6);
        }
      }
    }

    .mnt-adminEdit {
      padding: 0;
      border: 0;
    }
  }

  .mnt-time {
    text-transform: lowercase;
    font-family: $font-family-secondary;
    font-weight: $font-weight-medium;
    transition: color .25s ease 0s;
    font-size: $font-size-semi;
    color: rgba($black, .5);
  }

  img.mnt-PostImage {
    border-radius: 3px;
    object-fit: cover;
    max-width: 100%;
    aspect-ratio: 110 / 73;
    height: 73px;
    width: 110px;
  }
}

.mnt-FeedStickyLiveHeader {
  .mnt-live {
    height: 24px;
  }

  .mnt-tags .mnt-tag {
    height: 25px;
    padding: 3px 10px;
  }

  .mnt-LiveHeaderTitle {
    padding: 15px 0 30px 0;
    font-size: 25px;
    line-height: 25px;
  }
}

p.mnt-liveAuthor {
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
  font-size: 14px !important;
  line-height: 12px !important;
  color: $black
}

.mnt-LiveHeaderAuthorShare {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;

  .mnt-External {
    position: relative;
    display: flex;
    align-items: center;
    left: 10px;
    padding: 10px;
    color: $monza;
    font-size: 13px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    transition: color 0.3s ease 0s;

    svg {
      margin-right: 8px;
      width: 20px;
      height: 17px;
      fill: $monza;
      transition: fill 0.3s ease 0s;
    }

    &:hover {
      color: rgba($monza, 0.6);

      svg {
        fill: rgba($monza, 0.6);
      }
    }
  }
}

.mnt-LiveHeaderImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 3px;
}

.mnt-LiveHeaderImageWrapper {
  position: relative;
  display: block;

  .mnt-zoom {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;

    svg {
      width: 15px;
      height: 15px;
      fill: rgba($white, 1);
      filter: drop-shadow(0 0 3px $black);
    }
  }
}

.mnt-share-wrapper {
  position: relative;

  .mnt-shareLinks {
    right: 0;
  }
}

.mnt-articleCurrentlyLive {
  background-color: $wildsand;
}

.mnt-articleArchivedLive {
  background-color: $white;
}

.mnt-LiveHeaderImageCaption {
  font-family: $font-family-secondary;
  font-size: $font-size-sm;
  line-height: $line-height-20;
  font-weight: $font-weight-light;
  padding-top: 10px;
  color: #9E9E9E;
}