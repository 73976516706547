.mnt-shareLinks {
  padding: 35.5px 14.5px 14.5px 14.5px;
  border: 1px solid $gallery;
  border-radius: 0 0 3px 3px;
  border-top: none;
  background-color: $white;
  position: absolute;
  right: 2px;
  width: 200px;
  top: 0;
  z-index: 1;
  .mnt-claerShareLinks {
    position: absolute;
    right: 15px;
    top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    svg {
      width: 10px;
      height: 10px;
    }
    &:hover {
      svg {
        fill: $monza;
      }
    }
  }
  .mnt-shares {
    display: grid;
    grid-template-rows: max-content;
    grid-row-gap: 4.5px;
    button,
    a {
      padding: 10px #{(14/17)}rem;
      border: 1px solid rgba($black, .1);
      border-radius: 3px;
      color: rgba($black, .6);
      font-size: $font-size-small;
      font-weight: $font-weight-medium;
      letter-spacing: 0.28px;
      line-height: $line-height-18;
      text-transform: uppercase;
      font-family: $font-family-secondary;
      display: flex;
      align-items: center;
      background-color: $white;
      transition: background-color .25s ease 0s;
      &:hover {
        background-color: $monza;
        color: $white;
        svg {
          fill: $white;
        }
      }
    }
    svg {
      transition: fill .25s ease 0s;
      margin-right: 8px;
      width: 16px;
      height: 16px;
      fill: $monza;
      &.mnt-facebook {
        fill: $facebook;
      }
      &.mnt-messenger {
        fill: $messenger;
      }
      &.mnt-twitter {
        fill: $twitter;
      }
      &.mnt-whatsapp {
        fill: $whatsapp;
      }
      &.mnt-gmail {
        fill: $gmail;
      }
    }
  }
}
