.mnt-ToastContainer {
  position: fixed;
  z-index: 1100;
  bottom: -5em;
  left: 0;
  right: 0;
  white-space: nowrap;

  transition-duration: .3s;
  transition-property: bottom;

  pointer-events: none;

  &.start {
    bottom: 5em;

    @include media-breakpoint-up(md) {
      bottom: 2em;
    }
  }

  .mnt-ToastCenter {
    position: relative;
    text-align: center;
    pointer-events: none;
  }

  .mnt-Toast {
      pointer-events: none;
      padding: 11px 15px;
      font-family: $font-family-secondary;
      color: $black;
      font-weight: $font-weight-medium;
      font-size: $font-size-sm;
      line-height: $line-height-18;
      background-color: $alto;
      border-radius: 22px;
      border: none;
      text-transform: uppercase;
  }
}
