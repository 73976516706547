.mnt {
  figure.fslightbox-source {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    max-height: 100vh;
    width: auto !important;
    height: auto !important;
    @include media-breakpoint-up(md ) {
      max-width: 80vw;
      max-height: 80vh;
    }
    img {
      width: auto;
      max-height: inherit;
      max-width: 100%;
      height: auto;
    }
    figcaption {
      color: $white;
      padding: 1rem 1rem 1rem 0;
      align-self: flex-start;
    }
  }
}
.fslightbox-toolbar-button {
  &:first-child {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
