%colorWhite {
  color: $white;
}

.mnt-darkTheme {
  background-color: #090808;

  .mnt-Minuta {
    background-color: #090808;
    color: $white;
  }

  .mnt-FeedArticle.mnt-FeedArticleSeen {
    background-color: $shark;

    .mnt-tags.mnt-fade-end::after {
      background: linear-gradient(to right, rgba($shark, 0), $shark)
    }
  }

  a {
    @extend %colorWhite;
  }

  .mnt-layout__menu {
    .mnt-tags.mnt-fade-end::after {
      background: linear-gradient(to right, rgba($codgraydark, 0), $codgraydark);
    }
  }

  .mnt-carret-right {
    &::after {
      background: linear-gradient(to right, rgba($mineshaftLight, 0), $mineshaftLight);
    }
  }

  .mnt-carret-left {
    &::after {
      background: linear-gradient(to left, rgba($mineshaftLight, 0), $mineshaftLight);
    }
  }

  .mnt-settings .mnt-newsletter, .mnt-sideMenu a, .mnt-sideMenu .mnt-IconLink {
    @extend %colorWhite;

    &:hover {
      background-color: $mineshaft;
    }
  }

  .mnt-settings .mnt-newsletter svg,
  .mnt-sideMenu .mnt-IconLink svg {
    fill: $radicalred;
  }

  .mnt-Menu h5 {
    color: rgba($white, .5);
  }

  .mnt-Menu .mnt-LiveLink {
    a {
      @extend %colorWhite;

      &.active,
      &:hover {
        color: $radicalred;
      }
    }
  }

  .mnt-Menu .mnt-tags {
    border-color: rgba($white, 0.1);
  }

  .mnt-Menu .mnt-LiveLink,
  .mnt-Menu .mnt-LiveLinks,
  .mnt-sideNav,
  .mnt-sideMenu .mnt-IconLink:last-child {
    border-color: rgba($white, .1);
  }

  .mnt-sideMenu.mnt-bordered_bottom {
    border-color: rgba($white, .1);
  }

  .mnt-sideMenu {
    .mnt-IconLink {
      &.active {
        color: $radicalred;
      }
    }

    .mnt-NavLink {
      &.active {
        color: $radicalred;
      }
    }
  }

  .mnt-Switch {
    input {
      &:checked {
        + .mnt-slider {
          background-color: $radicalred;

          + .mnt-label {
            color: $radicalred;
          }
        }

        &:hover {
          + .mnt-slider {
            background-color: $dark_link_hover;

            + .mnt-label {
              color: $dark_link_hover;
            }
          }
        }
      }
    }
  }

  input[type="text"] {
    background-color: $DarkTheme_serch_box; //#2d2d2e
    color: rgba($white, 1);

    &::placeholder {
      color: rgba($white, .5);
    }
  }

  .mnt-btnGroup .mnt-btnItem {
    border-color: $mineshaftLight;
    background-color: $codgraydark;
  }

  .mnt-Switcher {
    background-color: $shark;
    border-color: rgba($white, .25);

    .mnt-label {
      color: $white;
    }

    &:hover {
      background-color: lighten($shark, 10%);

      .mnt-label {
        color: $radicalred;
      }
    }
  }

  .mnt-live {
    background-color: $white;
    color: $black;

    &::before {
      background-color: $black;
    }
  }

  .mnt-tags a {
    background-color: transparent;
    border-color: rgba($white, .25);
    color: rgba($white, .6);

    &.mnt-highlight {
      color: $radicalred;
    }

    &:hover {
      color: $dark_link_hover;
    }
  }

  .mnt-MenuSearchBox svg {
    fill: rgba($white, .5);
  }

  .mnt-Layout {
    .mnt-layout__content .mnt-layout__content-feed {
      border-color: $sharkdark;
    }

    .mnt-layout__bottom-toolbar-sk .mnt-logoMnt {
      background-color: $shark;
    }

    .mnt-layout__bottom-toolbar-cz .mnt-logoMnt {
      background-color: $shark;
    }

    .mnt-overlay__menu {
      background-color: rgba($white, .6);
    }

  }

  .mnt-FeedSticky {
    background-color: $DarkTheme_serch_box;

    .mnt-adminEdit {
      svg {
        fill: $white;
      }
    }

    .mnt-tags.mnt-fade-end::after {
      background: linear-gradient(to right, rgba($DarkTheme_serch_box, 0), $DarkTheme_serch_box);
    }

    header {
      border-color: rgba($white, .1);
      background-color: $shark;

      h1 {
        color: $white;
      }
    }

    &.mnt-border_bottom {
      border-color: rgba($white, .1);
    }

    .mnt-border_bottom {
      border-color: rgba($white, .1);
    }

    a {
      color: $radicalred;

      :hover {
        color: $dark_link_hover;
      }

      :visited {
        color: $link_visited;
      }
    }

    a.mnt-tag {
      color: rgba($white, .6);

      &.mnt-highlight {
        color: $radicalred;
      }

      &:hover {
        color: $dark_link_hover;
      }
    }

    article:not(:last-child) {
      border-color: rgba($white, .1);
    }

    .mnt-articleLive {
      .mnt-liveLink {
        color: $radicalred;
      }
      .mnt-liveLink:hover {
        color: $dark_link_hover;
      }
    }
  }

  .mnt-FeedSticky article ul li:not(:last-child)::marker {
    color: $radicalred;
  }

  .mnt-FeedSticky article ul li:last-child a {
    background-color: transparent;
    border-color: rgba($white, .25);

    :hover {
      color: $dark_link_hover;
    }

    :visited {
      color: $link_visited;
    }
  }

  .mnt-Search {
     h1 {
       background: $DarkTheme_menu_bcg;
     }
  }

  .mnt-FeedArticle {
    background: $DarkTheme_menu_bcg;
    color: $white;
    border-color: rgba($white, .1);

    .mnt-tags.mnt-fade-end::after {
      background: linear-gradient(to right, rgba($DarkTheme_menu_bcg, 0), $DarkTheme_menu_bcg);
    }

    .mnt-article ul li a,
    .mnt-article > p a,
    .mnt-article > article a {
      color: $radicalred;

      &:hover,
      &:active {
        color: $dark_link_hover;
      }

      &:visited {
        color: $link_visited;
      }

      &[data-link="ext"]:hover::after {
        background-color: $dark_link_hover;
      }

      &[data-link="ext"]:visited::after {
        background-color: $dark_link_hover;
      }
    }

    .tile {
      background-color: $shark;
    }

    .tile_call {
      color: rgba($white, .5);
    }
  }

  .mnt-PendingPostsCTA button {
    background-color: $radicalred;
  }

  .mnt-btn.mnt-pink {
    background-color: rgba($radicalred, .2);
    border-color: transparent;
    color: $radicalred;
  }
  .mnt-btn.mnt-btn-red {
    background-color: $radicalred;
  }

  .mnt-ToolbarDesk {
    .mnt-loader-sk {
      background-color: $black;

      &::before {
        background: $black url('../assets/svg/loading_bcg_light.svg') repeat-y scroll center 0/47px auto;
      }

      &::after {
        background: transparent url('../assets/svg/loading_after_light.svg') no-repeat scroll center 0/47px auto;
      }
    }
    .mnt-loader-cz {
      background-color: $white;
    }
  }

  .mnt-ToolbarMob {
    background-color: $shark;
    border-bottom: 1px solid $lightTuna;

    .mnt-loader-sk {
      background-color: $shark;

      &::before {
        background: transparent url('../assets/svg/loading_bcg_light.svg') repeat-y scroll center 0/47px auto;
      }

      &::after {
        background: transparent url('../assets/svg/loading_after_light_mob.svg') no-repeat scroll center 0/47px auto;
      }
    }
    .mnt-loader-cz {
      background-color: $white;
    }
  }

  .mnt-logoMnt {
    .mnt-logoTitle {
      color: $white;
    }

    .mnt-logo {
      &::after {
        background-color: $radicalred;
      }

      svg {
        fill: $white;
      }
    }
  }

  .mnt-hamburgerMenu svg {
    fill: $radicalred;
  }

  .mnt-FeedArticle .mnt-toolbar .mnt-time {
    color: rgba($white, .5);
  }

  .mnt-FeedArticle .mnt-toolbar a:hover .mnt-time {
    color: rgba($white, .5);
  }

  .mnt-FeedArticle .mnt-toolbar svg {
    fill: rgba($white, .4);
  }

  .mnt-FeedArticle .mnt-toolbar .mnt-External:hover svg {
    fill: rgba($white, .4);
  }

  .mnt-Menu {
    background-color: $DarkTheme_menu_bcg; //#1e1e1f
    border-color: rgba($white, 0.1);
  }

  .mnt-FeedSticky article ul li {
    color: white;
  }

  .mnt-backButton {
    svg {
      fill: $white;
    }

    span {
      color: $white;
    }
  }


  .mnt-ModalWrapper {
    background-color: rgba($black, .8);
  }

  .mnt-ModalWrapper .mnt-ModalContent {
    background-color: $codgray;

    h1, p {
      color: $white;
    }

    h3 {
      color: rgba($white, .5);
    }

    p {
      a {
        color: $radicalred;
        font-weight: $font-weight-medium;

        &:hover {
          color: $dark_link_hover;
        }
      }
    }
  }

  .mnt-shareLinks {
    background: $codgraydark;
    border-color: rgba($white, .1);

    .mnt-claerShareLinks:hover svg {
      fill: $radicalred;
    }

    .mnt-shares {
      button, a {
        background-color: transparent;
        border-color: rgba($white, .25);
        color: rgba($white, .6);

        &:hover,
        &.mnt-highlight {
          color: $radicalred;

          svg {
            fill: $radicalred;
          }
        }
      }
    }
  }

  .mnt-readMore {
    color: rgba($white, 0.5);
    border-color: rgba($white, 0.5);
  }

  .mnt-widgetSettings {
    background-color: $shark;

    .mnt-widgetSettings-header p {
      color: $white;
    }

    .mnt-widgetSettings-caption {
      color: $white;
    }

    .mnt-widgetSettings-header {
      span {
        svg {
          fill: $white;
        }
      }
    }

    .mnt-widgetSettings-selector {
      button {
        background-color: #090808;
        color: rgba($white, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.15);

        &.active {
          background-color: rgba($white, 0.95);
          color: rgba($black, 1);
        }
      }
    }

    .mnt-widgetSettings-description {
      color: $dustygray;
      border-bottom: 1px solid rgba($white, 0.07);
    }

    .mnt-label.active {
      color: $radicalred;
    }
  }

  .mnt-viewOnMnt {
    background: $shark;
    color: $radicalred;
    border-color: #eaeaea;

    &::after {
      background-color: $dark_link_hover;
    }

    &:hover {
      color: $dark_link_hover;
    }
  }

  .mnt-loadMoreOverlay {
    background: $codgray;
    background: linear-gradient(0deg, rgba($codgray, 1) 40%, rgba($codgray, 0) 100%);
  }


  .mnt-articleLive {
    border-color: rgba($white, .1);
  }

  .mnt-articleLive .mnt-time {
    color: rgba($white, .5);
  }

  .mnt-articleLive .mnt-liveShare-wrapper .mnt-live-header .mnt-time {
    color: rgba($white, .5);
  }

  .mnt-articleLive .mnt-liveShare-wrapper .mnt-External svg {
    fill: rgba($white, .4);
  }

  .mnt-articleLive .mnt-liveShare-wrapper .mnt-External:hover svg {
    fill: rgba($white, .4);
  }

  .mnt-LiveHeaderAuthorShare {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;

    .mnt-External {
      svg {
        fill: $radicalred;
      }

      &:hover {
        color: $dark_link_hover;

        svg {
          fill: $dark_link_hover;
        }
      }
    }
  }

  p.mnt-liveAuthor {
    color: $white;
  }

  .mnt-articleCurrentlyLive {
    background-color: $DarkTheme_serch_box;
  }

  .mnt-articleArchivedLive {
    background-color: $DarkTheme_menu_bcg;
  }


  .mnt-widgetTopbar-fixed {
    .tile_title a {
      color: $white !important;
    }
  }
}