%after_before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 2px;
  height: 126px;
}

@keyframes load_after {
  0% {transform: translateY(-8%)}
  20% {transform: translateY(-9%)}
  50% {transform: translateY(3%)}
  60% {transform: translateY(0%)}
  80% {transform: translateY(-25%)}
  80.1% {transform: translateY(0%)}
  100% {transform: translateY(-8%)}
}

@keyframes load_before {
  0% {transform: translateY(0%)}
  60% {transform: translateY(0%)}
  80% {transform: translateY(-25%)}
  80.1% {transform: translateY(0%)}
  100% {transform: translateY(0%)}
}

.mnt-loader-sk {
  width: 47px;
  height: 47px;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  .mnt-widgetTopbar &,
  .mnt-ToolbarMob & {
    background-color: $white;
  }
  background-color: $black;
  &::after {
    @extend %after_before;
    background: transparent url('../assets/svg/loading_after_light.svg') no-repeat scroll center 0/47px auto;
    .mnt-widgetTopbar &,
    .mnt-ToolbarMob & {
      background: transparent url('../assets/svg/loading_after.svg') no-repeat scroll center 0/47px auto;
    }
    transform: translate3d(0, -8%, 0);
  }
  &::before {
    @extend %after_before;
    background: $black url('../assets/svg/loading_bcg_light.svg') repeat-y scroll center 0/47px auto;
    .mnt-widgetTopbar &,
    .mnt-ToolbarMob & {
      background: $white url('../assets/svg/loading_bcg.svg') repeat-y scroll center 0/47px auto;
    }
    transform: translate3d(0, 0, 0);
  }
  &.mnt-animate {
    &::after {
      animation: load_after 1.4s linear 0s infinite;
    }
    &::before {
      animation: load_before 1.4s linear infinite 0s;
    }
  }
}

.mnt-ToolbarMob {
  &.mnt-default {
    .mnt-loader-sk,
    .mnt-loader-cz {
      margin: 0;
    }
  }
  .mnt-loader-sk,
  .mnt-loader-cz {
    margin: 0 auto;
    + .mnt-logoTitle {
      display: none;
    }
  }
}


@keyframes logo_animation_cz {
  from {transform: translateY(-57px) translateX(-5px)}
  to {transform: translateY(0px) translateX(-5px)}
}

@keyframes logo_animation_cz_widget {
  from {transform: translateY(-86px) scale(1.35)}
  to {transform: translateY(-34px) scale(1.35)}
}

.mnt-loader-cz {
  width: 55px;
  height: 55px;
  position: relative;
  overflow: hidden;
  text-align: center;
  .mnt-widgetTopbar &,
  .mnt-ToolbarMob & {
    background-color: $white;
  }
  background-color: $white;
  &::before {
    content: '';
    display: inline-block;
    width: 65px;
    height: 120px;
    background: url('../assets/svg/logo-mnt-animate-cz.svg') repeat-y;
    transform: translateY(-57px) translateX(-5px);
  }
  &.mnt-animate {
    &::before {
      animation: logo_animation_cz 1.075s infinite linear;
    }
  }
  .mnt-widgetTopbar.mnt-widgetTopbar-cz & {
    margin: 5.5px;
    width: 44px;
    height: 44px;
    &::before {
      width: 44px;
      height: 120px;
      transform: translateY(-86px) scale(1.35);
    }
    &.mnt-animate {
      &::before {
        animation: logo_animation_cz_widget 1.075s infinite linear;
      }
    }
  }
}