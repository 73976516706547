.mnt-Toolbar {
  height: 100%;
  @include media-breakpoint-up(md) {
    background-color: $black;
  }
}
.mnt-ToolbarDesk {
  display: none;
  @include media-breakpoint-up(md) {
    display: grid;
    height: 100%;
    grid-template-columns: 340px minmax(55px, 340px) minmax(320px, 800px);
    justify-content: space-between;
    align-content: center;
    column-gap: 0;
  }
  @include media-breakpoint-up(xxl) {
    grid-template-columns: 510px minmax(320px, 650px);
    column-gap: calc((100vw - 1920px + 70px) / 2 );
  }
}
.mnt-ToolbarMob {
  display: grid;
  grid-template-columns: 33.333% 33.333% 33.333%;
  height: 100%;
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.mnt-LeftBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mnt-logoMnt {
  padding: 0;
  display: flex;
  align-items: flex-end;
  @include media-breakpoint-up(md) {
    padding: 8px 0 0 7px;
    .mnt-logoTitle {
      margin-top: -5px;
    }
    &.mnt-p-0 .mnt-logoTitle {
      margin-top: 0 !important;
    }
  }
  .mnt-logo {
    position: relative;
    width: 47px;
    height: 47px;
    &::after {
      content: "";
      position: absolute;
      width: 47px;
      height: 4px;
      background-color: $monza;
      left: 0;
      top: 18px;
    }
  }
  svg {
    width: 47px;
    height: 47px;
    fill: $black;
    position: relative;
    top: 0;
    @include media-breakpoint-up(md) {
      fill: $white;
    }
  }
  .mnt-logoTitle {
    font-family: $font-family-secondary;
    font-weight: 400;
    align-self: center;
    margin-left: 16px;
    color: $black;
    small {
      font-weight: $font-weight-light;
    }
    @include media-breakpoint-up(md) {
      color: $white;
    }
  }
}
.mnt-logoN {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $monza;
  height: 100%;
  width: 165px;
  .mnt & {
    color: $white;
  }
  svg {
    margin-left: 11px;
    width: 24px;
    height: 24px;
    fill: $white;
  }
}

.mnt-logoN-cz {
  line-height: 30px;
  font-size: 24px;
  svg {
    margin-left: 7px;
    width: 30px;
    height: 30px;
    fill: $monza;
    background-color: $white;
    padding: 3px;
  }
}

.mnt-backLink {
  color: $white;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    fill: $white;
    width: 15px;
    height: 15px;
  }
  span {
    font-size: 15px;
    font-weight: $font-weight-medium;
  }
}
.mnt-ToolbarActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 35px;
  height: 100%;
}
.mnt-CenterBar {
  display: flex;
  position: static;
  justify-content: center;
  width: auto;
  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }
  @include media-breakpoint-up(xxl) {
    position: absolute;
    left: calc(50% - 20px);
    transform: translateX(-50%);
    height: 100%;
    width: 780px;
    //justify-content: flex-start;
  }
  .mnt-widgetTopbar & {
    justify-content: center;
  }
}
.mnt-RightBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.mnt-hamburgerMenu {
  padding-right: 16px;
  svg {
    width: 22px;
    height: 22px;
    fill: $monza;
    &.hamburger {
      fill: $monza !important;
    }
  }
}

.mnt-login-cta, .mnt-my-account-cta {
  color: $white;
  font-family: $font-family-secondary;
  padding-left: 20px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
}

.mnt-my-account-cta svg {
  fill: $chateaugreen;
}

.mnt-login-cta svg {
  fill: $radicalred;
}

.mnt-p-0 {
  padding: 0;
}