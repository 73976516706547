@font-face {
  font-family: 'Lumin Sans';
  src: url('../assets/fonts/lumin__sans__2.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: 'Lumin Sans';
  src: url('../assets/fonts/lumin__sans__4.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: 'Lumin Serif';
  src: url('../assets/fonts/lumin__serif__3.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap
}
//@font-face {
//  font-family: 'Lumin Serif';
//  src: url('../assets/fonts/lumin__serif__3i.woff') format('woff');
//  font-weight: 300;
//  font-style: normal;
//}
@font-face {
  font-family: 'Lumin Serif';
  src: url('../assets/fonts/lumin__serif__5.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap
}
//@font-face {
//  font-family: 'Lumin Serif';
//  src: url('../assets/fonts/lumin__serif__5i.woff') format('woff');
//  font-weight: 500;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'serif';
//  font-style: normal;
//  font-weight: 400;
//  font-display: swap;
//  src: url('../assets/serif__3.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'serif';
//  font-style: italic;
//  font-weight: 400;
//  font-display: swap;
//  src: url('../assets/serif__3i.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'serif';
//  font-style: normal;
//  font-weight: 700 ;
//  font-display: swap;
//  src: url('../assets/serif__5.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'serif';
//  font-style: italic;
//  font-weight: 700;
//  font-display: swap;
//  src: url('../assets/serif__5i.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'sans';
//  font-style: normal;
//  font-weight: 200;
//  font-display: swap;
//  src: url('../assets/sans__2.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'sans';
//  font-style: normal;
//  font-weight: 400;
//  font-display: swap;
//  src: url('../assets/sans__4.woff') format('woff');
//}
