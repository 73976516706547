%content-padding {
  padding: 15px 20px;
  max-width: 100vw;
}

.mnt-FeedSticky {
  background-color: $wildsand;
  position: relative;

  .mnt-tags {
    padding: 8px 0;
  }

  header {
    padding: 21px 21px 20px 21px;
    background-color: $white;

    h1 {
      color: $black;
      font-weight: $font-weight-medium;
      font-size: $font-size-xxl;
      line-height: $line-height-30;
      font-family: $font-family-primary;
    }
  }

  &.mnt-border_bottom {
    border-bottom: 1px solid rgba(#181818, 0.07);
  }

  .mnt-border_bottom {
    border-bottom: 1px solid rgba($codgray, .07);
  }

  article {
    @extend %content-padding;
    .mnt-header-excerpt {
      font-weight: $font-weight-light;
      line-height: 28px;
      font-weight: $font-weight-light;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: $line-height-26;
      }
      strong, b {
        font-weight: $font-weight-medium;
      }
      p, ul {
        margin-bottom: 15px;
        margin-top: 15px;
        &:last-child {margin-bottom: 0;}
        &:only-child, &:first-child {margin-top: 0;}
      }
    }

    ul {
      padding: 0 20px 5px 20px;

      li {
        font-size: $font-size-normal;
        font-weight: $font-weight-light;
        font-family: $font-family-primary;
        line-height: $line-height-26;
        list-style: disc;
        list-style-position: outside;

        strong, b {
          font-weight: $font-weight-medium;
        }

        &::marker {
          color: $monza;
        }

        @include media-breakpoint-up(md) {
          font-size: 20px;
        }
      }
    }

    a {
      color: $monza;
    }

    div > strong {
      display: inline-block;
    }
  }

  article:not(:last-child) {
    border-bottom: 1px solid rgba(#181818, 0.07);
  }

  .mnt-adminEdit {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $alto;
    width: 23px;
    height: 23px;
    border-radius: 12px;

    svg {
      width: 8px;
      height: 8px;
      fill: $black;
    }
  }
}
