// Colors
$white: rgb(255, 255, 254);
$black: #000000;
$codgraydark: #101010;
$sharkdark: #19191A;
$codgray: #181818;
$shark: #212123;
$mineshaft: #282828;
$mineshaftLight: #2d2d2d;
$tuna: #323234;
$lightTuna: #363638;
$satan: #666;
$dustygray: #999999;
$alto: #D8D8D8;
$gallerydarken: #F0F0F0;
$wildsand: #F5F5F5;
$alabaster: #FCFCFC;
$gallery: #EFEFEF;
$monza: #BF001F;
$link_hover: #A12237;
$link_visited: #6E6E6E;
$shiraz: #C3102D;
//$radicalred: #FB3B5B;
$radicalred: #DA1434;
$dark_link_hover: #B55262;
$chateaugreen: #46B864;
$black10: rgba($black, 0.1);
$white25: rgba($white, .25);
$facebook: #3A5798;
$messenger: #00B2FF;
$twitter: #1DA1F2;
$x-twitter: #000;
$whatsapp: #29B200;
$gmail: #EA4335;
$DarkTheme_menu_bcg: #1e1e1f;
$DarkTheme_serch_box: #2d2d2e;

// Fonts
$font-family-primary: 'Lumin Serif',serif;
$font-family-secondary: 'Lumin Sans',sans-serif;
$font-size-xl: 17px;
$font-size-sm: 14px;
$font-size-base: 17px;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-normal: 500;
$font-weight-light: 300;

$font-size-xxl: 30px;
$font-size-huge: 22px;
$font-size-large: 21px;
$font-size-medium: 19px;
$font-size-normal: 17px;
$font-size-semi: 15px;
$font-size-small: 13px;

$line-height-42: 42px;
$line-height-31: 31px;
$line-height-30: 30px;
$line-height-26: 26px;
$line-height-23: 23px;
$line-height-20: 20px;
$line-height-19: 19px;
$line-height-18: 18px;

$topOffset: 55px;

$namespace: 'mnt';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1580px
);
