.mnt-Sections {
  padding-bottom: 28.8px;
}
.mnt-Section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  margin-bottom: 10px;
  background-color: $wildsand;
  padding: 0 20px;
  color: $black;
  line-height: 23px;
  svg {
    width: 10px;
    height: 10px;
    fill: rgba($black, .4);
    transform: rotateZ(-90deg);
  }
}
