.mnt-btnGroup {
  display: flex;
  align-items: center;
  .mnt-settings & {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .mnt-btnItem {
    height: 45px;
    border-color: $gallery;
    border-width: 1px;
    border-style: solid solid solid none;
    background-color: $white;
    transition: background-color 0.25s ease 0s;
    display: flex;
    width: 100%;
    &:hover {
      background-color: $wildsand;
    }
    &:first-child {
      border-style: solid;
      border-radius: 6px 0 0 6px
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
  .mnt-settings & {
    margin: 0;
    @include media-breakpoint-down(sm) {
      .mnt-btnItem {
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
        justify-content: center;
        width: 50%;
        display: flex;
      }
    }
  }
}
.mnt-btn {
  white-space: nowrap;
  padding: 12px 15px;
  background-color: $black;
  color: $white;
  font-family: $font-family-secondary;
  font-weight: $font-weight-medium;
  font-size: $font-size-sm;
  line-height: $line-height-18;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba($white, 0.25);
  border-radius: 3px;
  text-transform: uppercase;
  cursor: pointer;
  span {
    padding: 0 8px;
    @include media-breakpoint-up(xl) {
      display: block;
    }
  }
  svg {
    width: 15px;
    height: 15px;
    fill: $white;
    &.mnt-carret {
      fill: $white !important;
      fill-opacity: 0.5;
      width: 13px;
      height: 13px;
    }
  }
  + .mnt-btn {
    margin-left: 10px;
  }
  &.mnt-green {
    background-color: $chateaugreen;
    border-color: $chateaugreen;
  }
  &.mnt-anonym {
    color: $radicalred;
    svg {
      fill: $radicalred;
    }
  }
  &.mnt-loged {
    svg {
      fill: $chateaugreen
    }
  }
  &.mnt-pink {
    background-color: rgba($monza, .08);
    color: $monza;
  }
  &.mnt-highlight {
    background-color: $white;
    color: $monza;
    border-color: $alto;
    svg {
      fill: $monza;
    }
  }
  &.mnt-btn-small {
    padding: 7px 10px;
    height: 31px;
    font-size: 13px;
    svg {
      width: 7px;
    }
  }
  &.mnt-btn-wider {
    padding: 12px 20px;
  }
  &.mnt-readMore {
    border-radius: 0;
    border: none;
    background-color: transparent;
    color: $satan;
    font-family: $font-family-primary;
    text-transform: none;
    font-size: 17px;
    line-height: 15.06px;
    font-weight: 300;
    padding: 0 0 0 0;
    margin: 0 0 20px ;
    border-bottom: 1px solid #dedede;
    svg {
      fill: $satan;
      width: 9px;
      margin-left: 4px;
      padding-top: 6px;
    }
  }
  &.mnt-btn-sharp {
    border-radius: 0;
  }
  &.mnt-btn-red {
    background-color: $monza;
    font-weight: $font-weight-normal;
    font-size: $font-size-small;
  }
}
