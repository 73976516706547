.mnt-Minuta {
  font-size: $font-size-xl;
}

.mnt-Layout {
  *, *:after, *:before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.mnt-js-scrolling-element {
  scroll-behavior: smooth;
}
body.mnt {
  overflow-y: scroll;
}

.mnt-Minuta {
  min-height: 100vh;
}

.mnt-Minuta,
body.mnt {
  font-size: $font-size-normal;
  font-family: $font-family-primary;
  color: $black;
  font-weight: $font-weight-medium;
  background-color: $alabaster;
  // overflow: hidden;
  &.mnt-darkTheme {
    color: $white;
    background-color: $black;
  }
  &.mnt-BodyOffset {
    width: 100vw;
    height: 100vw;
    overflow: hidden;
  }
  &.mnt-BodyOffsetModal {
    width: 100vw;
    height: 100vw;
    overflow: hidden;
  }
}

body:not(.mnt) .mnt-Minuta {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba($dustygray, 0);
    border-radius: 2px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba($dustygray, 0.75);
    }
  }
}

.mnt-rows {
  flex-direction: row;
}

.mnt-cols {
  flex-direction: column;
}

.mnt-feature__container {
  width: 100%;
  position: relative;
  max-width: 100%;
  z-index: 10;

  @include media-breakpoint-up(md) {
    width: 100%;
    position: fixed;
    top: 55px;
    left: 0;
    margin-top: 0 !important; // reset dynamic padding from iframe height.
  }
}

.mnt-Layout {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  //height: 100vh;
  .mnt-layout__bottom-toolbar-sk {
    display: grid;
    grid-template-columns: 50vw 50vw;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 1001;
    @include media-breakpoint-up(md) {
      display: none;
    }
    .mnt-logoMnt {
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      .mnt-logo {
        width: 28px;
        height: 28px;
        &:after {
          width: 100%;
          height: 3px;
          top: 10.5px !important;
        }
      }
      .mnt-logo-cz {
        &::after {
          content: "";
          height: 0;
          background-color: transparent;
        }
      }
      svg {
        width: 28px;
        height: 28px;
        top: 0;
      }
    }
    .mnt-logoN {
      width: auto;
    }
  }
  .mnt-layout__bottom-toolbar-cz {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 1001;
    @include media-breakpoint-up(md) {
      display: none;
    }
    .mnt-logoMnt {
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      .mnt-logo {
        width: 28px;
        height: 28px;
        &:after {
          width: 100%;
          height: 3px;
          top: 10.5px !important;
        }
      }
      .mnt-logo-cz {
        &::after {
          content: "";
          height: 0;
          background-color: transparent;
        }
      }
      svg {
        width: 28px;
        height: 28px;
        top: 0;
      }
    }
    .mnt-logoN {
      width: auto;
    }
  }
  .mnt-overlay__menu {
    background-color: rgba($black, .6);
    position: fixed;
    visibility: hidden;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transition: visibility 0s ease-out .5s, opacity .5s ease-out 0s;
    z-index: 1003;
  }
  .mnt-layout__menu {
    max-width: 640px;
    width: calc(100vw - 65px);
    position: fixed;
    right: 0;
    transform: translate3d(640px, 0, 0);
    transition: transform .5s cubic-bezier(0, 0, 0, 1);
    display: flex;
    bottom: auto;
    top: 0;
    overflow: hidden;
    z-index: 1005;
    padding-right: 0;
    scrollbar-width: thin;
    scrollbar-thumb: rgba($dustygray, 0) transparent;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba($dustygray, 0);
      border-radius: 2px;
    }
    &:hover {
      scrollbar-width: thin;
      scrollbar-thumb: rgba($dustygray, 0.75) transparent;
      &::-webkit-scrollbar-thumb {
        background-color: rgba($dustygray, 0.75);
      }
    }
    @include media-breakpoint-up(md) {
      padding-left: 0;
      position: fixed;
      left: 0;
      transform: translate3d(0, 0, 0);
      max-width: 100%;
      width: 320px;
      top: 55px;
    }
    @include media-breakpoint-up(xxl) {
      left: calc((100vw - 1580px + 20px) / 2);
    }
    &.open {

      transform: translate3d(0, 0, 0);
      top: 0 !important;
      & + .mnt-overlay__menu {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s ease-out 0s, opacity .5s ease-in 0s;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
  }
  .mnt-layout__toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $topOffset;
    transition: top .25s ease 0s;
    z-index: 1001;
    background-color: $white;
    @include media-breakpoint-up(sm) {
      //position: sticky;
    }
    @include media-breakpoint-down(sm) {
      &.mnt-scrolling {
        top: -$topOffset;
        &+.mnt-layout__content {
          @include media-breakpoint-down(xs) {
            margin-top: 0;
            .mnt-Feed .mnt-Post-hash:before {
              display: block;
              content: ' ';
              visibility: hidden;
              margin-top: 0;
              height: 0;
            }
          }
        }
      }
    }
  }
  .mnt-layout__container {
    padding: 55px 0 0 0;
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    grid-column-gap: 30px;
    @include media-breakpoint-up(md) {
      padding: 55px 0 0 340px;
      display: block;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: minmax(500px, 650px);
      justify-content: center;
      padding: 55px 397px 0 340px;
    }
  }
  .mnt-layout__content {
    transition: margin-top 0.3s ease-in-out 0s;
    .mnt-layout__content-serch_result,
    .mnt-layout__content-feed {
      margin-top: 0;
      scroll-behavior: smooth;
      position: relative;
      z-index: 1;
      flex:2;
      display: flex;
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border: none;
      @include media-breakpoint-down(sm) {
        top: -2px;
      }
      @include media-breakpoint-up(md) {
        border-radius: 3px 3px 0 0;
        border: 1px solid rgba($codgray, .07);
        margin: 30px auto 0;
        padding-top: 0;
      }
      @include media-breakpoint-up(xl) {
        //min-height: 100vh;
        width: 100%;
        max-width: 780px;
        justify-self: center;
      }
      @include media-breakpoint-up(xxl) {
        width: 100%;
        max-width: 780px;
        justify-self: center;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .mnt-layout__content-aside {
      display: none;
    }
    @include media-breakpoint-up(sm) {

      .mnt-layout__content-aside {
        height: #{'calc(100vh - 85px)'};
        border-radius: 3px 3px 0 0;
        overflow: hidden;
        display: flex;
        color: $white;
        margin-top: 30px;
        transform: translateX(0);
        position: fixed;
        right: 0;
        width: 377px;
        top: 55px;
        a {
          color: $white;
        }
      }
    }
    @include media-breakpoint-up(xxl) {
      .mnt-layout__content-aside {
        right: calc((100vw - 1580px + 20px) / 2);
      }
    }
  }
}
.mnt-mb-15 {
  margin-bottom: 15px;
}
.mnt-desktop {
  display: none !important;
  @include media-breakpoint-up(md) {
    display: inherit !important;
  }
}

button {
  border: none;
  outline-style: none;
  background-color: transparent;
  cursor: pointer;
  &:focus,
  &:active {
    outline-style: none;
  }
}

@include media-breakpoint-down(md) {
  .mnt-Layout.mnt-layout--widget {
    height: 100vh;

    .mnt-layout__content {
      .mnt-layout__content-feed {
        padding-top: 0;
      }
    }
  }
}
.mnt-hidden {
  display: none !important;
}
