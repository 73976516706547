%readMoreBtn {
  width: 100%;
  padding: 16px 40px;
  margin: 20px auto;
  @include media-breakpoint-up(sm) {
    width: auto;
  }
}

.mnt-FeedArticle {
  padding: 0 20px;
  border-bottom: 1px solid rgba($codgray, .07);
  position: relative;
  z-index: auto;
  @include media-breakpoint-up(md) {
    padding: 0 20px 0 20px;
  }

  .mnt-article-title {
    font-weight: $font-weight-medium;
    font-size: 22px;
    line-height: 27px;
    padding-bottom: 15px;
  }

  .mnt-Important-cz {
    span::after {
      content: '!';
      position: relative;
      right: -7px;
      display: inline-block;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      font-size: 12px;
      color: $white;
      background: #bf2134;
    }
  }

  .mnt-article {
    &.mnt-Important-sk {
      p:first-child {
        font-size: 22px;
        line-height: 27.5px;
      }
    }

    & > article figure {
      margin-bottom: 1rem;
    }

    & > article,
    & > p {
      margin-bottom: 15px;
      font-family: $font-family-primary;

      a {
        color: $monza;
        font-size: inherit;
        font-weight: $font-weight-medium;

        &:active,
        &:hover {
          color: $link_hover;
        }

        &:visited {
          color: $link_visited;
        }

        &:hover {
          text-decoration: underline;
        }

        &[data-link="ext"]:hover::after {
          background-color: $link_hover;
        }

        &[data-link="ext"]:visited::after {
          background-color: $link_visited;
        }

        &[data-link="ext"]::after {
          -webkit-mask: url('../assets/svg/extermal-feed-link.svg') no-repeat 50% 50%;
          mask: url('../assets/svg/extermal-feed-link.svg') no-repeat 50% 50%;
          -webkit-mask-size: cover;
          mask-size: cover;
          background-color: $monza;
          display: inline-block;
          content: '';
          height: 10px;
          width: 10px;
          margin-left: 4px;
        }
      }

      strong {
        font-size: inherit;
        font-weight: $font-weight-medium;

      }
    }

    ul {
      margin-left: 20px;
      margin-bottom: 15px;
      padding: 0;

      li {
        line-height: 24px;
        font-family: $font-family-primary;
        font-size: $font-size-normal;
        font-weight: 300;
        list-style: disc;
        @include media-breakpoint-up(md) {
          font-size: 20px;
        }

        +li {
          margin-top: 3px;
          @include media-breakpoint-up(md) {
            margin-top: 5px;
          }
        }

        a {
          color: $monza;
        }
      }
    }

    & + .mnt-btn {
      //@extend %readMoreBtn;
    }

    & + .mnt-tags {
      //margin-bottom: 20px;
    }
  }

  .mnt-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-top: -15px;
    text-transform: uppercase;
    //margin-bottom: 18px;
    & > a {
      &:hover {
        .mnt-time {
          color: rgba($black, .7);
        }
      }
    }

    .mnt-time {
      text-transform: lowercase;
      font-family: $font-family-secondary;
      font-weight: $font-weight-normal;
      transition: color .25s ease 0s;
      font-size: $font-size-semi;
      color: rgba($black, .5);
    }

    svg {
      width: 20px;
      height: 17px;
      fill: rgba($black, .4);
      transition: fill 0.3s ease 0s;
    }

    .mnt-adminEdit {
      svg {
        fill: rgba($black, 0.4);
      }

      &:hover {
        svg {
          fill: rgba($black, 0.6);
        }
      }
    }

    .mnt-External {
      padding: 10px;
      position: relative;
      left: 12px;

      &:hover {
        svg {
          fill: rgba($black, 0.6);
        }
      }
    }
  }

  .tile {
    background-color: $wildsand;
    padding: 15px 16px;
    margin-left: -17px;
    margin-right: -17px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .tile_figure {
      width: 112px;
      height: 100%;
      margin: 0;
      padding: 1.5rem 0 0 0;
      display: block;
      flex: 0 0 100px;

      img {
        border-radius: 3px;
      }
    }

    .tile_title {
      width: 100%;
      font-size: 17px;
      line-height: 20px;
      padding-right: 13px;
    }

    .tile_call {
      display: inline-flex;
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 12px;
      font-weight: $font-weight-medium;
      color: rgba($black, 0.5);
      margin-bottom: 9px;
    }
  }

  figure {
    padding: 0 0 15px;
    margin: 0 -20px 0 -20px;
    max-width: 100vw;
    width: 100vw;
    position: relative;
    @include media-breakpoint-up(sm) {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }

    .mnt-zoom {
      position: absolute;
      top: 0;
      right: 0;
      padding: 15px;

      svg {
        width: 15px;
        height: 15px;
        fill: rgba($white, 1);
        filter: drop-shadow(0 0 3px $black);
      }
    }

    & + .mnt-btn {
      @extend %readMoreBtn;
    }

    img {
      max-width: 100%;
      height: auto;
      aspect-ratio: attr(widht) / attr(height);
    }

    figcaption {
      font-family: $font-family-secondary;
      font-size: $font-size-sm;
      line-height: $line-height-20;
      font-weight: $font-weight-light;
      padding: 10px 20px 0;
      color: #9E9E9E;
      @include media-breakpoint-up(sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    & + .mnt-tags {
      padding-top: 0;
      //margin-bottom: 20px;
    }
  }

  .video-responsive {
    position: relative;
    margin-top: 17px;
    margin-bottom: 15px;

    &:before {
      content: '';
      display: block;
      padding-bottom: 56.25%; // 16:9
      height: 0;
    }

    iframe {
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    & + .mnt-btn {
      @extend %readMoreBtn;
    }
  }

  .mnt-blankLink {
    display: flex;
    align-items: center;
    margin: 0 0 20px;

    & + .mnt-btn {
      @extend %readMoreBtn;
    }
  }
}

.mnt-FeedArticleSeen {
  background-color: $wildsand;

  .mnt-toolbar {
    .mnt-time {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        height: 5px;
        width: 5px;
        background-color: $monza;
        border-radius: 5px;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 7px;
      }
    }
  }
}