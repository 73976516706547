.mnt-Search {
  h1 {
    padding: 21px 21px 25px 21px;
    font-size: $font-size-xxl;
    line-height: $line-height-42;
  }
  .mnt-toolbar {
    .mnt-time {
      margin-bottom: 15px;
    }
  }
  .searches {
    margin-bottom: 28.8px;
  }
  h3 {
    text-align: center;
    padding: 21px 21px 25px 21px;
  }
  article h3 {
    text-align: left;
    padding: 0;
  }
}
