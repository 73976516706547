// third part css, functions and mixins
@import "~bootstrap/scss/functions"; // dependency
@import "~bootstrap/scss/variables"; // dependency
@import "~bootstrap/scss/mixins"; // dependency
@import "~reset-css/sass/reset"; // dont use for widget

*, *:after, *:before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	word-wrap: break-word;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;
}
@import "config";
@import "font";
@import 'layout';
@import 'elements/tags';
@import 'elements/forms';
@import 'elements/buttons';
@import 'elements/loader';
@import 'elements/sections';
@import 'typo';
@import 'important';
@import 'menu';
@import 'toolbar';
@import 'feed';
@import 'feed_article';
@import 'feed_sticky';
@import 'pending_posts_cta';
@import 'aside';
@import 'search';
@import 'modal';
@import 'share_links';
@import 'back_button';
@import 'toast';
@import 'darkTheme';
@import 'live_blocks';

// Modules
@import "modules/fslightbox";
