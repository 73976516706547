%posts-params {
  font-size: $font-size-base;
  line-height: $line-height-20;
  padding: 11px 16px 20px;
  border-bottom: 1px solid $tuna;
  transition: background-color 0.3s ease 0s;
  &:last-child {
    border-bottom: none;
    padding-bottom: 7px;
  }
  &:hover {
    background-color: rgba($shark, 0.7);
  }
}
.mnt-Aside {
  display: none;
  @include media-breakpoint-up(xl) {
    display: block;
    margin-right: 20px;
  }
  width: 100%;
  .mnt-AsideHeader {
    top: 0;
    z-index: 1001;
    position: sticky;
    padding: 16px;
    background-color: $DarkTheme_serch_box;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  h3 {
    font-size: $font-size-huge;
    line-height: $line-height-31;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
      fill: $radicalred;
      width: 20px;
      height: 20px;
      margin-right: 19px;
    }
  }
  .mnt-HeaderLogo {
    width: 34px;
    height: 34px;
    padding: 7px;
    background-color: $monza;
    border-radius: 2.16px;
    svg {
      fill: $white;
      width: 20px;
      height: 20px;
    }
  }
  .mnt-TrendingPost {
    display: grid;
    grid-template-columns: 200px auto;
    grid-column-gap: 11px;
    @extend %posts-params;
    .mnt-PostImage {
      display: block;
      position: relative;
      height: 83px;
      width: 100%;
      overflow: hidden;
      img {
        border-radius: 3px;
        max-width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        aspect-ratio: attr(width) / attr(height);
      }
    }
  }
  .mnt-CurrentPost {
    display: block;
    @extend %posts-params;
    .mnt-time {
      display: block;
      font-family: $font-family-secondary;
      font-weight: $font-weight-medium;
      color: rgba($white, .5);
      font-size: $font-size-semi;
      line-height: $line-height-20;
      margin-bottom: 5px;
    }
  }
  .mnt-postBlock {
    background-color: $DarkTheme_menu_bcg;
    height: #{'calc(50vh - 56px)'};
    width: 100%;
    border-radius: 0 0 3px 3px;

    &:first-child {
      margin-bottom: 10px;
    }
  }
  .mnt-CurrentPosts {
    position: relative;
    height: #{'calc(100% - 66px)'};
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba($white, 0);
      border-radius: 2px;
    }
    &:hover {
      scrollbar-width: thin;
      scrollbar-color: rgba($white, 0.5) transparent;
      -ms-overflow-style: initial;
      &::-webkit-scrollbar-thumb {
        background-color: rgba($white, 0.5);
      }
    }
  }
}
