.mnt-FeedSticky article.mnt-articleOnlyTags > .mnt-tags {
  align-items: flex-start;
  height: 48px;
}

.mnt-tags-wrapper {
  position: relative;
}

.mnt-tags {
  display: inline-grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 2.5px;
  max-width: 100%;
  overflow-x: scroll;
  align-items: center;
  margin-left: -2.5px;
  justify-content: flex-start;
  font-family: $font-family-secondary;
  position: relative;
  padding-bottom: 16px;

  .mnt-Posts & {
    align-items: flex-start;
  }

  &.mnt-fade-end::after {
    content: '';
    position: sticky;
    right: -1px;
    bottom: 0;
    width: 65px;
    height: 31px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), $alabaster);
    pointer-events: none;
  }

  .mnt-FeedArticleSeen &.mnt-fade-end {
    &::after {
      background: linear-gradient(to right, rgba(255, 255, 255, 0), $wildsand);
    }
  }

  scrollbar-width: none;

  &::-webkit-scrollbar {
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .mnt-FeedSticky &.mnt-fade-end {
    &::after {
      background: linear-gradient(to right, rgba($wildsand, 0), $wildsand);
    }
  }

  .mnt-Menu & {
    display: block;
    padding: 18px 20px 10px;
    border-bottom: 1px solid $gallery;
    @include media-breakpoint-up(sm) {
      display: block;
    }

    .mnt-tag {
      margin: 0 5px 5px 0;
    }
  }

  &.searches {
    padding-top: 0;
  }

  .mnt-tag {
    white-space: nowrap;
    padding: 7px 10px;
    height: 31px;
    background-color: $white;
    border: 1px solid rgba($black, .1);
    border-radius: 3px;
    font-family: $font-family-secondary;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    font-size: $font-size-small;
    transition: color 0.25s ease 0s;
    color: rgba($black, .6);

    &.mnt-highlight,
    &:hover {
      color: $monza;
    }
  }

  &.mnt-space-btw {
    justify-content: space-between;
    align-items: center;

    .mnt-live {
      margin-bottom: 0;
    }
  }
}

@keyframes blik {
  0% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

.mnt-live {
  display: flex;
  align-items: center;
  font-family: $font-family-secondary;
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  line-height: 12px;
  text-transform: uppercase;
  color: $white;
  background-color: $black;
  padding: 4px 6px;
  border-radius: 3px;
  align-self: center;

  &::before {
    content: "";
    margin-right: 5px;
    width: 4px;
    height: 4px;
    background-color: $white;
    border-radius: 50%;
    position: relative;
    animation: blik 0.5s linear 0s infinite alternate;
  }
}

.mnt-carret {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 20px;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    width: 75px;
    height: 31px;
    pointer-events: none;
  }


  svg {
    fill: $satan;
    width: 8px;
    height: 12px;
  }

  button {
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}

.mnt-carret-right {
  right: 0;

  &::after {
    right: 0;
    background: linear-gradient(to right, rgba($wildsand, 0), $wildsand);
  }
}

.mnt-carret-left {
  left: 0;

  &::after {
    left: -3px;
    background: linear-gradient(to left, rgba($wildsand, 0), $wildsand);
  }
}